/*
	
                       .,,uod8B8bou,,.
              ..,uod8BBBBBBBBBBBBBBBBRPFT?l!i:.
         ,=m8BBBBBBBBBBBBBBBRPFT?!||||||||||||||
         !...:!TVBBBRPFT||||||||||!!^^""'   ||||
         !.......:!?|||||!!^^""'            ||||
         !.........||||                     ||||
         !.........||||  ##                 ||||
         !.........||||                     ||||
         !.........||||                     ||||
         !.........||||                     ||||
         !.........||||                     ||||
         `.........||||                    ,||||
          .;.......||||               _.-!!|||||
   .,uodWBBBBb.....||||       _.-!!|||||||||!:'
!YBBBBBBBBBBBBBBb..!|||:..-!!|||||||!iof68BBBBBb.... 
!..YBBBBBBBBBBBBBBb!!||||||||!iof68BBBBBBRPFT?!::   `.
!....YBBBBBBBBBBBBBBbaaitf68BBBBBBRPFT?!:::::::::     `.
!......YBBBBBBBBBBBBBBBBBBBRPFT?!::::::;:!^"`;:::       `.  
!........YBBBBBBBBBBRPFT?!::::::::::^''...::::::;         iBBbo.
`..........YBRPFT?!::::::::::::::::::::::::;iof68bo.      WBBBBbo.
  `..........:::::::::::::::::::::::;iof688888888888b.     `YBBBP^'
    `........::::::::::::::::;iof688888888888888888888b.     `
      `......:::::::::;iof688888888888888888888888888888b.
        `....:::;iof688888888888888888888888888888888899fT!  
          `..::!8888888888888888888888888888888899fT|!^"'   
            `' !!988888888888888888888888899fT|!^"' 
                `!!8888888888888888899fT|!^"'
                  `!988888888899fT|!^"'
                    `!9899fT|!^"'
                      `!^"'
*/

/* Important Note: A Header Slide Out Menu was added to the site 
after the global components were already built. As a result, some of the logic
is located in a seperate file. (header-slide-menu.js) This is for the hamburger menu.*/

// Get current url
var url = window.location.href;

// Desktop Expand for Seach Bar
jQuery("#expand-search").click(function() {
    jQuery(".expanded-search-row").slideToggle();
});

// Desktop Current Active Page Indicator
jQuery(function() {
    // Apply active class where needed - Primary Links
    jQuery(".links-col a").each(function() {
        var includesPound = this.href.includes("#");
        if (url === (this.href) && includesPound === false) {
            jQuery(this).addClass("active-link");
        }
    });

    // Apply active class where needed - Secondary Links
    jQuery("#menu-global-header-1 a").each(function() {
        var includesPound = this.href.includes("#");
        var includesContact = this.href.includes("/contact/");
        var hasChildren = jQuery(this).parent('li.menu-item').hasClass('menu-item-has-children');
        if (url === (this.href) && includesPound === false) {
            jQuery(this).addClass("active-link");
            jQuery(".sub-menu-row").show();
            jQuery(this).closest("ul.sub-menu").show();
            if (includesContact === true) {
                jQuery(".sub-menu-row").hide();
            }
        }
    });
});

// Desktop Global Nav - Show/Hide Sub Menu
jQuery('#menu-global-header li.menu-item-has-children').click(function() {
    var menu_item_title = jQuery(this).children().html();
    menu_item_title = menu_item_title.toLowerCase().split(' ').join('-');
    //jQuery('.hamburger-sub-menu-row').hide();
    jQuery('.sub-menu-row').show();
    jQuery('.header-nav-container .sub-menu-row #menu-global-header-1 li.' + menu_item_title + ' ul.sub-menu').show();
    jQuery('.header-nav-container .sub-menu-row #menu-global-header-1 li.' + menu_item_title).siblings().children('ul.sub-menu').hide();
    jQuery(this).css("background", "none");
});


/*
                               88          88 88             
                               88          "" 88             
                               88             88             
88,dPYba,,adPYba,   ,adPPYba,  88,dPPYba,  88 88  ,adPPYba,  
88P'   "88"    "8a a8"     "8a 88P'    "8a 88 88 a8P_____88  
88      88      88 8b       d8 88       d8 88 88 8PP"""""""  
88      88      88 "8a,   ,a8" 88b,   ,a8" 88 88 "8b,   ,aa  
88      88      88  `"YbbdP"'  8Y"Ybbd8"'  88 88  `"Ybbd8"'  
*/

// Mobile Menu Hamburger/Open Button Actions
jQuery('#hamburger-icon').click(function() {
    // Update Menu Icon
    jQuery('#hamburger-icon').hide();
    jQuery('#close-icon').show();
    // Update Menu Display
    jQuery('.mobile-menu-expand-row').show();
});

// Mobile Menu Close Button Actions
jQuery('#close-icon').click(function() {
    // Update Menu Icon
    jQuery('#hamburger-icon').show();
    jQuery('#close-icon').hide();
    // Update Menu Display
    jQuery('.mobile-menu-expand-row').hide();
});

// Mobile Reveal Sub Menus
jQuery('#menu-global-header-2 .menu-item-has-children').click(function() {
    jQuery(this).children('.sub-menu').toggle();
});